<template>
  <div id="news">
    <header id="news-header">
      <div id="news-header-logo">
        <router-link to="/Games">
          <img v-if="isMobileMode" class="home" src="@/assets/img/mobile/home.svg" />
        </router-link>
        <router-link to="/Games">
          <img id="news-header-logo-img" src="@/assets/news/news-logo.svg" />
        </router-link>
      </div>
      <nav id="news-header-links-nav">
        <ul id="news-header-links">
          <li v-for="(link, i) in links" :key="i" class="news-header-link">
            <router-link :to="link.href" active-class="active">{{ link.name }}</router-link>
          </li>
        </ul>
      </nav>
    </header>
    <main id="news-main">
      <router-view />
    </main>
    <footer id="news-footer">
      <div id="news-footer-bg">
        <video id="news-footer-video" autoplay loop muted playsinline>
          <source src="@/assets/news/news-footer-video.mp4" />
        </video>
      </div>
      <div id="news-footer-content">
        <div id="news-footer-logo">
          <img id="news-footer-logo-img" src="@/assets/news/news-logo.svg" />
        </div>
        <div id="news-footer-copyright"> {{ $t('news.copyright') }} </div>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        links: [
          // { name: this.$t('news.home'), href: '/News/home' },
          // { name: this.$t('news.superpig'), href: '/News/superpig' },
          // { name: this.$t('news.super8'), href: '/News/super8' },
          { name: this.$t('news.news'), href: '/News/news' },
          { name: this.$t('news.best'), href: '/News/best' },
          // { name: this.$t('news.schedule'), href: '/News/schedule' },
        ],
      };
    },
    computed: {
      isMobileMode() {
        return process.env.VUE_APP_UI === 'mobile';
      },
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  #news {
    --news-header-height: 64px;
    --news-footer-height: 48px;

    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #eee;
  }

  #news-header {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    width: 100%;
    height: var(--news-header-height);
    background-color: #283240;
  }

  #news-header-logo {
    position: relative;
    height: 100%;
    padding: 0 2vw 0 1vw;

    &:before {
      position: absolute;
      left: -4vw;
      top: 0;
      right: 0;
      bottom: 0;
      transform: skew(-16deg);
      background-color: #1c232d;
      content: '';
    }
  }

  .home {
    position: relative;
    width: auto;
    height: 100%;
    padding: 8px;
    color: #fff;
  }

  #news-header-logo-img {
    position: relative;
    width: auto;
    height: 100%;
  }

  #news-header-links-nav {
    flex-grow: 1;
  }

  #news-header-links {
    display: flex;
    flex-flow: row wrap;
    //justify-content: center;
    //width: 100%;
    margin-left: 12%;
    margin-bottom: 10px;
    gap: 8px;
  }

  .news-header-link {
    display: inline;

    a {
      color: #bbb;
      font-size: 14px;
      cursor: pointer;

      &:hover,
      &:active {
        color: #19bd88;
      }
    }
  }

  #news-main {
    position: relative;
    width: 100%;
    min-height: calc(100% - var(--news-header-height) - var(--news-footer-height));
  }

  #news-footer {
    position: relative;
    width: 100%;
    height: var(--news-footer-height);
  }

  #news-footer-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }

  #news-footer-video {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  #news-footer-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  #news-footer-logo {
    position: relative;
    height: 100%;
    padding: 12px 0 8px 4px;
  }

  #news-footer-logo-img {
    width: auto;
    height: 100%;
  }

  #news-footer-copyright {
    position: relative;
    padding: 0 0 12px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
  }

  @media screen and (min-width: 720px) {
    #news-header-links {
      gap: 16px;
    }

    .news-header-link {
      font-size: 16px;
    }
  }
</style>
